

.form__error {
	border-left:  3px solid #BC2354;
	color:  #BC2354;
	font-weight:  500;
	margin-bottom:  20px;
	padding:  10px;
}

.form__field {
	margin-bottom:  20px;
}

.form__field__label {
	display: block;
	margin-bottom:  5px;
}

.form__field__label__hint {
	margin-left:  20px;
	opacity:  0.6;
}

.form__field__input {
	padding:  10px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  500px;
}

.form__field__input--short {
	width:  240px;
}

.form__field__input--tiny {
	width:  110px;
}

.form__field__input[type="text"]:disabled, .ListUsers__search:disabled {
	opacity:  0.5;
}

.form__field__error {
	margin-top:  5px;
	color:  #BC2354;
	font-weight:  bold;
}