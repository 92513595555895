.RefundListItem__type {
	background-color:  #76B82A;
	padding:  2px 2px;
	text-align: center;
	border-radius: 50px;
	color:  #FFF;
}


.RefundListItem__type--pending {
	background-color:  #EE7523;
}

.RefundListItem__type--complete {
	background-color:  #76B82A;
}

.RefundListItem__type--error {
	background-color:  #BC2354;
}