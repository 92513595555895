.ThumbnailUploader {
	background-color:  #F3F5F7;
	padding:  20px;
}

.ThumbnailUploader__thumbnail {
	width:  240px;
	height:  180px;
	background-color:  #FFF;
	margin-right:  20px;
	background-size:  cover;
}

.ThumbnailUploader__thumbnail--processing {
	background-image: url('processing.svg');
	background-size:  100px;
	background-repeat: no-repeat;
	background-position: center;
}