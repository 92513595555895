.Switch {
	display:  flex;
	align-items: center;
	margin-bottom:  20px;
}

.Switch--on .Switch__switch {
	justify-content: flex-end;
}

.Switch--on .Switch__switch__handle {
	background-color:  #76B82A
}

.Switch__switch {
	display:  flex;
	background-color:  #F3F5F7;
	width:  50px;
	padding:  5px;
	border-radius: 50px;
	margin-right:  10px;
	border:  1px solid #C9C9C9;
	cursor: pointer;
}


.Switch__switch__handle {
	background-color:  #BC2354;
	height:  15px;
	width:  20px;
	border-radius: 50px;
}

