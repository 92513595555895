.ProgressBar {
	border-radius: 50px;
	background-color: #FFF;
	padding:  2px;
	margin:  10px 0;
}

.ProgressBar__progress {
	background-color: #15205C;
	height:  10px;
	width:  30px;
	border-radius: 30px;
}