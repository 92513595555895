
.Button {
	display:  inline-block;
	color:  #FFF;
	padding:  10px 15px;
	text-align: center;
	border:  0;
	outline:  0;
	font-weight:  600;
	margin-right:  10px;
	cursor: pointer;
	border-radius: 2px;
	min-width:  100px;
	font-family: inherit;
	font-size:  inherit;
	text-decoration: none;
}

.Button:hover {
	opacity:  0.8;
}



/* Colour Variants
-----------------------------------------------*/

.Button--colour-blue, .Button--colour-default {
	background-color:  #1D71B8;
}

.Button--colour-orange {
	background-color:  #EE7523;
}

.Button--colour-red {
	background-color:  #BC2354;
}