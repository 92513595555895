/* Fonts
-----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');


/* Resetter
-----------------------------------------------*/

* {
	margin:  0;
	padding:  0;
	box-sizing: border-box;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	background-repeat: no-repeat;
  	background-position: center center;

}


/* Body
-----------------------------------------------*/

body {
	font-size:  15px;
	font-family: 'Nunito', sans-serif;
	font-weight:  600;
	color: #374442;
	background-color: #F3F5F7;
}


/* Headings, Paragraphs and Anchors
-----------------------------------------------*/

h1, h2 {
	font-size:  18px;
}

h2 {
	margin-bottom: 20px;
}

p {
	font-size: 16px;
	color: #374442;
	margin:  25px 0;
}

a {
	color:  #02A4AE;
}



.table {
	width:  100%;
	border-collapse: collapse;
	background-color:  #FFF;
	margin:  20px 0;
}

.table th {
	padding: 10px;
	border-bottom:  1px solid #EBF0F5;
	border-top:  1px solid #EBF0F5;
	text-align: left;
	background-color:  #F7F9FC;
}

.table td {
	padding:  10px;
	border-bottom:  1px solid #EBF0F5;
}

.table tbody tr:hover td {
	background-color:  #F6F8FB;
}

.table th.r, .table td.r {
	text-align: right;
}

.table th.c, .table td.c {
	text-align: center;
}

.table tbody tr.red td {
	background-color:  #F7E4EA !important;
}


.pagination {
	display:  flex;
	justify-content: space-between;
}

.pagination > div {
	cursor: pointer;
	color:  #02A4AE;
	font-weight: 600;
}

.table-caption {
	margin-top:  20px;
	opacity: 0.75;
	font-weight:  600;
}

.input {
	padding:  10px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  300px;
}


.colour--red {
	color:  #BC2354;
}


.ghost {
	opacity:  0.6;
	font-weight:  600;
}

.refunded {
	display: inline-block;
	background-color: #BC2354;
	color:  #FFF;
	border-radius: 50px;
	padding:  3px 10px;
}

