
.DietarySelector {

}

.DietarySelector__item {
	display: flex;
	align-items: center;
}

.DietarySelector__item__checkbox {
	padding:  3px;
	margin-right:  5px;
}

.DietarySelector__item__label {
	padding:  3px;
}